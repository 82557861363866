import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`MIPs Under Review`}</h1>
    <h2>{`MIPs Currently Under Review`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`MIP №`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Date Submitted`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Proposal Link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description/Title`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/RFC/MIP14"
            }}>{`MIP14`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Protocol DAI Transfer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/RFC/MIP15"
            }}>{`MIP15`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dark Spell Mechanism`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-07-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/RFC/MIP16"
            }}>{`MIP16`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Weekly Governance Cycle`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-07-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/RFC/MIP17"
            }}>{`MIP17`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekly Actual Debt Ceiling and Actual Risk Premium Adjustments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-07-07`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/RFC/MIP18"
            }}>{`MIP18`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Meta-Parameter Adjustments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-07-08`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/RFC/MIP19"
            }}>{`MIP19`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Liquidations System 1.1 Upgrade`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-07-21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/RFC/MIP20"
            }}>{`MIP20`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Target Price Adjustment Module (Vox)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`PRs for new MIPs, or sub-proposals other than MIP6c2 (Collateral Onboarding) will be merged into this directory, under the `}<inlineCode parentName="p">{`current`}</inlineCode>{` folder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      